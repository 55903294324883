<!--
 * @Description: 用户审核--用户实名审核-表单页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-25 15:14:37
 -->
<template>
  <div class="realNameAuditDetail-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      submitText="保存"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      :footerShow="footerShow"
    >
      <col2-detail>
        <col2-block title="用户信息">
          <col2-item label="姓名" :text="detailItem.userName" />
          <col2-item label="性别" :text="detailItem.sex" />
          <col2-item label="手机号" :text="detailItem.phone" />
          <col2-item label="身份" :text="detailItem.userType" />
          <col2-item label="用户房号" :text="detailItem.communityAddress" />
        </col2-block>
        <col2-block title="实名认证信息">
          <col2-item :span="24" label="认证姓名" :text="detailItem.realname" />
          <col2-item :span="24" label="证件照片">
            <div>
              <v-preview :imgs="imageUrls"></v-preview>
            </div>
          </col2-item>
        </col2-block>
        <col2-block title="操作信息" v-if="footerShow">
          <el-form-item label="证件类型">
            <v-select v-model="form.cardType" :options="cardTypeOps"></v-select>
          </el-form-item>
          <el-form-item
            :label="form.cardType === 4 ? '其他证件号' : `${cardTypeMap[form.cardType]}号`"
            prop="cardNum"
            :rules="cardNumRules"
          >
            <v-input
              v-model="form.cardNum"
              :placeholder="form.cardType === 4 ? '请输入其他证件号' : `请输入${cardTypeMap[form.cardType]}号`"
              :maxlength="form.cardType === 1 ? 18 :20"
              :width="250"
            ></v-input>
          </el-form-item>
          <el-form-item v-if="form.cardType === 1" label="出生日期">
            {{birthday}}
          </el-form-item>
          <el-form-item v-if="form.cardType === 1" label="性别">
            {{cardSex}}
          </el-form-item>
          <el-form-item
            class="el-form-wrapper"
            label="操作备注"
            prop="approveRemark"
            :rules="[
              { required: true, message: '请输入操作备注', trigger: 'blur' }
            ]"
          >
            <v-textarea
              v-model="form.approveRemark"
              placeholder="请输入操作备注"
            ></v-textarea>
          </el-form-item>
          <el-form-item
            class="el-form-wrapper"
            label="审核状态"
            prop="status"
            :rules="[
              { required: true, message: '请选择审核状态', trigger: 'change' }
            ]"
          >
            <v-select v-model="form.status" :options="statusOps"></v-select>
          </el-form-item>
        </col2-block>
        <col2-block title="操作信息" v-if="!footerShow">
          <col2-item :span="24" label="审核状态" :text="detailItem.status" />
          <col2-item
            :span="24"
            label="操作备注"
            :text="detailItem.approveRemark"
          />
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>

// 引用的类
import { getDetailURL, approveHandleURL } from './api'
import { cardTypeMap, cardTypeOps, statusOps } from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'
import { idCardHelper } from 'common/utils'
import { idNumberRegular } from 'common/regular'
import { vPreview } from 'components/control'

export default {
  name: '',
  /// 引用的组件处理
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    vPreview
  },

  created () {
    // 设置title
    this.$setBreadcrumb('实名审核详情')
  },
  // 加载详情数据
  mounted () {
    this.getDetailData()
  },

  /// 必有方法
  data () {
    // 证件号码正则
    const regularCheckout = (rule, value, callback) => {
      let hasError = false
      if (this.form.cardType === 1) {
        hasError = !idNumberRegular.test(value || '')
      } else {
        hasError = !value || value.length === 0
      }
      if (hasError) {
        callback(new Error('请输入正确的证件号码'))
      } else {
        callback()
      }
    }
    return {
      submitUrl: approveHandleURL,
      submitConfig: {
        submitMethod: 'put',
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      detailItem: {},
      //
      form: {
        id: this.$route.query.id,
        ownerId: '',
        cardType: undefined,
        approveRemark: '',
        status: 1,
        realname: '',
        cardNum: '',
        dataObject: ''
      },
      cardTypeOps: cardTypeOps,
      cardTypeMap: cardTypeMap,
      statusOps: statusOps,
      footerShow: false,
      cardNumRules: [{ required: true, validator: regularCheckout, trigger: 'blur' }]
    }
  },

  computed: {

    birthday () {
      /// 根据身份证号，处理显示生日
      let cardNum = this.form.cardNum || ''
      return idCardHelper.getBirthday(cardNum)
    },

    cardSex () {
      /// 根据身份证号，处理显示性别
      let cardNum = this.form.cardNum || ''
      return idCardHelper.getSex(cardNum)
    },

    imageUrls () {
      let arr = []
      let cardImg = this.detailItem.cardImg || ''
      if (cardImg.length) {
        arr.push({ src: cardImg })
      }

      let cardBackImg = this.detailItem.cardBackImg || ''
      if (cardBackImg.length) {
        arr.push({ src: cardBackImg })
      }
      return arr
    }
  },

  methods: {
    /// 获取详情数据
    getDetailData () {
      let _this = this
      let getParames = {
        params: {
          id: this.$route.query.id,
          ownerId: this.$route.query.ownerId
        }
      }
      this.$axios
        .get(getDetailURL, getParames)
        .then(res => {
          if (res.status === 100) {
            _this.detailItem = res.data || {}
            _this.footerShow = _this.detailItem.status === '待审核'
            if (_this.footerShow) {
              _this.updatForm()
            }
          }
        })
    },

    // 更新form数据
    updatForm () {
      let dataObject = `${this.detailItem.userName}/${this.detailItem.phone}`
      let form = {
        id: this.detailItem.id,
        ownerId: this.detailItem.ownerId,
        cardType: this.detailItem.cardType,
        approveRemark: '',
        status: 1,
        realname: this.detailItem.realname,
        cardNum: this.detailItem.cardNum,
        dataObject: dataObject,
        communityId: this.detailItem.communityId
      }

      this.form = Object.assign({}, form)
    }
  }
}
</script>

<style scope lang="scss">
.realNameAuditDetail-wrapper {
  display: flex;

  .remark-wrapper {
    margin-top: 10px;
  }

  .el-form-wrapper {
    width: 100%;
  }
}
</style>
