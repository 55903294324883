var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "realNameAuditDetail-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitText: "保存",
              submitUrl: _vm.submitUrl,
              footerShow: _vm.footerShow,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "用户信息" } },
                [
                  _c("col2-item", {
                    attrs: { label: "姓名", text: _vm.detailItem.userName },
                  }),
                  _c("col2-item", {
                    attrs: { label: "性别", text: _vm.detailItem.sex },
                  }),
                  _c("col2-item", {
                    attrs: { label: "手机号", text: _vm.detailItem.phone },
                  }),
                  _c("col2-item", {
                    attrs: { label: "身份", text: _vm.detailItem.userType },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "用户房号",
                      text: _vm.detailItem.communityAddress,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "实名认证信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "认证姓名",
                      text: _vm.detailItem.realname,
                    },
                  }),
                  _c("col2-item", { attrs: { span: 24, label: "证件照片" } }, [
                    _c(
                      "div",
                      [_c("v-preview", { attrs: { imgs: _vm.imageUrls } })],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.footerShow
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件类型" } },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.cardTypeOps },
                            model: {
                              value: _vm.form.cardType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cardType", $$v)
                              },
                              expression: "form.cardType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.form.cardType === 4
                                ? "其他证件号"
                                : `${_vm.cardTypeMap[_vm.form.cardType]}号`,
                            prop: "cardNum",
                            rules: _vm.cardNumRules,
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder:
                                _vm.form.cardType === 4
                                  ? "请输入其他证件号"
                                  : `请输入${
                                      _vm.cardTypeMap[_vm.form.cardType]
                                    }号`,
                              maxlength: _vm.form.cardType === 1 ? 18 : 20,
                              width: 250,
                            },
                            model: {
                              value: _vm.form.cardNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cardNum", $$v)
                              },
                              expression: "form.cardNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.cardType === 1
                        ? _c("el-form-item", { attrs: { label: "出生日期" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.birthday) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm.form.cardType === 1
                        ? _c("el-form-item", { attrs: { label: "性别" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.cardSex) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-form-wrapper",
                          attrs: {
                            label: "操作备注",
                            prop: "approveRemark",
                            rules: [
                              {
                                required: true,
                                message: "请输入操作备注",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: { placeholder: "请输入操作备注" },
                            model: {
                              value: _vm.form.approveRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "approveRemark", $$v)
                              },
                              expression: "form.approveRemark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-form-wrapper",
                          attrs: {
                            label: "审核状态",
                            prop: "status",
                            rules: [
                              {
                                required: true,
                                message: "请选择审核状态",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.footerShow
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "审核状态",
                          text: _vm.detailItem.status,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "操作备注",
                          text: _vm.detailItem.approveRemark,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }