// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}ownerInfo/getOwnerRealnameApproveList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}ownerInfo/exportOwnerRealnameApprovePending`
// 导出列表
const exportNoPassURL = `${API_CONFIG.butlerBaseURL}ownerInfo/exportOwnerRealnameApproveNoPass`
// 获取详情
const getDetailURL = `${API_CONFIG.butlerBaseURL}ownerInfo/getOwnerRealnameApproveDetail`
// 审核操作
const approveHandleURL = `${API_CONFIG.butlerBaseURL}ownerInfo/realnameApprove`

export {
  getListURL,
  exportListURL,
  exportNoPassURL,
  getDetailURL,
  approveHandleURL
}
