import { generateMapByOpts } from 'common/utils'

/// 用户类型ops
const userTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '业主/法人',
    value: 1
  },
  {
    text: '家属/员工',
    value: 2
  },
  {
    text: '租客',
    value: 3
  },
  {
    text: '伙伴',
    value: 4
  },
  {
    text: '嘉宾',
    value: 5
  }
]

const sexOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]

/// 证件类型的ops
const cardTypeOps = [
  {
    text: '身份证',
    value: 1
  },
  {
    text: '军官证',
    value: 2
  },
  {
    text: '护照',
    value: 3
  },
  {
    text: '其他',
    value: 4
  }
]

/// 审核操作ops
const statusOps = [
  {
    text: '审核通过',
    value: 1
  },
  {
    text: '审核不通过',
    value: 2
  }
]

const cardTypeMap = generateMapByOpts(cardTypeOps)
const statusMap = generateMapByOpts(statusOps)

export {
  userTypeOps,
  sexOps,
  cardTypeOps,
  cardTypeMap,
  statusOps,
  statusMap
}
